import React from "react";

const Header = () => {
	return (
		<nav className="bg-primary border-gray-200 px-2 sm:px-4 py-4 dark:bg-gray-800">
			<section className="w-full px-8 text-gray-700 bg-white">
				<div className="container flex flex-col flex-wrap items-center justify-between py-5 mx-auto md:flex-row max-w-7xl">
					<div className="relative flex flex-col md:flex-row">
						<a href="#_" className="flex items-center mb-5 font-medium text-gray-900 lg:w-auto lg:items-center lg:justify-center md:mb-0">
							<span className="mx-auto text-xl font-black leading-none text-gray-900 select-none">TechB Softwares</span>
						</a>
						<nav className="flex flex-wrap items-center mb-5 text-base md:mb-0 md:pl-8 md:ml-8 md:border-l md:border-gray-200">
							<a href="#_" className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900">Home</a>
							<a href="#_" className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900">Features</a>
							<a href="#_" className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900">Pricing</a>
						</nav>
					</div>
				</div>
			</section>
		</nav>
	);
};

export default Header;
