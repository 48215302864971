import React from "react";
import * as Components from "../Components";

const Home = () => {
	return (
		<div>
			<Components.HomeHeader />
			<Components.BenefitsBanner />
			<Components.Features />
			<Components.Testomonials />
			<Components.Plans />
		</div>
	);
};

export default Home;
