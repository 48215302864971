import React, { useState } from "react";
import { submitEnquiry } from "../Services/enquiry";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

const HomeHeader = () => {
	const [message, setMessage] = useState({});
	const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
	let recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY; //eslint-disable-line

	const [formData, setFormData] = useState({
		"first_name": "",
		"email": "",
		"last_name": "",
		"mobile_no": "",
		"hotel_name": ""
	});

	const onValueChange = (e) => {
		const target = e.target;
		const name = target.name;
		const value = target.value;
		setMessage({});
		setFormData({ ...formData, [name]: value });
	};

	const showMessage = () => {
		if (message) {
			switch (message.type) {
			case "success":
					return <p className="text-green-500 text-sm">{message.text}</p> //eslint-disable-line

			case "error":
					return <p className="text-red-500 text-sm">{message.text}</p> //eslint-disable-line

			default:
				break;
			}
		}
	};

	const validateFormData = () => {
		if (
			!formData.first_name ||
			!formData.last_name ||
			!formData.email ||
			!formData.hotel_name ||
			!formData.mobile_no ||
			!isCaptchaVerified
		) {
			setMessage({
				"type": "error",
				"text": "All fields are mandatory!"
			});
			return true;
		}
		return false;
	};

	const submitEnquiryHandler = () => {
		if (validateFormData()) return;

		submitEnquiry(formData).then(response => {
			if (response.status === 201) {
				setMessage({
					"type": "success",
					"text": "Hurray! Demo has been successfully scheduled🎉!",
				});
				setFormData({
					"first_name": "",
					"email": "",
					"last_name": "",
					"mobile_no": "",
					"hotel_name": ""
				});
				return;
			}
			setMessage({
				"type": "error",
				"text": "Opps! Something went wrong. Please try again later.",
			});
		});
	};

	return (
		<section className="px-2 py-28 bg-white md:px-0">
			<div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
				<div className="flex flex-wrap items-center sm:-mx-3">
					<div className="w-full md:w-1/2 md:px-3">
						<div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
							<h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
								<span className="block xl:inline">Useful Tools to</span>
								<span className="block text-indigo-600 xl:inline"> Help You Build Faster.</span>
							</h1>
							<p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">It's never been easier to build beautiful websites that convey your message and tell your story.</p>
							<div className="relative flex flex-col sm:flex-row sm:space-x-4">
								<a href="#_" className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-indigo-600 rounded-md sm:mb-0 hover:bg-indigo-700 sm:w-auto">
									Try It Free
									<svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
								</a>
								<a href="#_" className="flex items-center px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600">
									Learn More
								</a>
							</div>
						</div>
					</div>
					<div className="w-full md:w-1/2">
						<div className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl">
							<section className="bg-white">
								<div className="p-6 mx-auto max-w-screen-md">
									<div className="mb-8">
										<h2 className="text-4xl tracking-tight font-extrabold text-center text-gray-900">Setup Free Demo</h2>
										<p className="text-gray-800 text-center">Schedule a demo now!</p>
									</div>
									<form action="#" className="space-y-6">
										<div className="flex gap-3">
											<div className="w-1/2">
												<label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900">
													First Name
												</label>
												<input
													type="text"
													id="firstName"
													name="first_name"
													value={formData.first_name}
													onChange={onValueChange}
													className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
													placeholder="e.g Jivan"
													required={true}
												/>
											</div>
											<div className="w-1/2">
												<label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900">
													Last Name
												</label>
												<input
													type="text"
													id="lastName"
													name="last_name"
													value={formData.last_name}
													onChange={onValueChange}
													className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
													placeholder="e.g Nath"
													required={true}
												/>
											</div>
										</div>
										<div>
											<label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
												Email
											</label>
											<input
												type="email"
												id="email"
												name="email"
												value={formData.email}
												onChange={onValueChange}
												className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
												placeholder="e.g username@example.com"
												required={true}
											/>
										</div>
										<div>
											<label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900">
												Mobile No
											</label>
											<input
												type="mobile"
												id="mobile"
												name="mobile_no"
												value={formData.mobile_no}
												onChange={onValueChange}
												className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
												placeholder="e.g 9092837498"
												required={true}
											/>
										</div>
										<div>
											<label htmlFor="restaurant" className="block mb-2 text-sm font-medium text-gray-900">
												Name of Restaurant/Hotel/Resort
											</label>
											<input
												type="text"
												id="restaurant"
												name="hotel_name"
												value={formData.hotel_name}
												onChange={onValueChange}
												className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
												placeholder="e.g Demo Restaurant"
												required={true}
											/>
										</div>
										<div>
											<GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
												<GoogleReCaptcha onVerify={() => setIsCaptchaVerified(true)} />
											</GoogleReCaptchaProvider>
										</div>

										{showMessage()}
										<div>

											<button
												onClick={submitEnquiryHandler}
												type="button"
												className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-black sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300">
												Book a Demo
											</button>
										</div>
									</form>
								</div>
							</section>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default HomeHeader;