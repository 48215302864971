const Testimonials = [
	{
		"id": 1,
		"name": "John Doe",
		"designation": "CEO of Something",
		"image": "https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1700&amp;q=80",
		"text": "This is a no-brainer if you want to take your business to the next level. If you are looking for the ultimate toolset, this is it!"
	},
	{
		"id": 2,
		"name": "Jen Doe",
		"designation": "CTO of Business",
		"image": "https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2547&amp;q=80",
		"text": "Thanks for creating this service. My life is so much easier. Thanks for making such a great product."
	},
	{
		"id": 3,
		"name": "Jen Doe",
		"designation": "CTO of Business",
		"image": "https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2547&amp;q=80",
		"text": "Thanks for creating this service. My life is so much easier. Thanks for making such a great product."
	}
];
export default Testimonials;