import client from "./axiosConfig";

export const submitEnquiry = (params) => {
	const config = {
		url: "/merchant/enquiry/",
		method: "POST",
		data: params
	};
	const response = client(config);
	return response;
};
