import React from "react";
import { Header, Footer } from "../../Components";
import { Outlet } from "react-router-dom";

const Layout = () => {
	return (
		<React.Fragment>
			<div>
				<Header />
				<main className="min-h-screen bg-slate-100">
					<Outlet />
				</main>
				<Footer />
			</div>
		</React.Fragment>
	);
};

export default Layout;
