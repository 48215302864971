import axios from "axios";

const client = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL, //eslint-disable-line
	headers: {
		"Content-Type": "application/json",
	},
});

// Response interceptor to handle errors and responses
client.interceptors.response.use(
	(response) => {
		return {
			data: response.data,
			status: response.status,
			error: null,
		};
	},
	(error) => {
		return {
			data: null,
			status: error.response.status,
			error: error.response.data,
		};
	}
);

// Request interceptor to handle requests
client.interceptors.request.use((config) => {
	return config;
});

export default client;
